<template>
  <span
    class="game-end-countdown"
    :class="{ 'end-close': endClose, ended: ended, 'last-hour': lastHour }"
  >
    <span class="text" v-if="ended">
      {{ endTimeLabel }}
    </span>
    <span class="text" v-else>
      {{ endInTimeLabel }}
    </span>
    <span v-if="!ended" class="time">
      <template v-if="object.hours.number > 9">
        {{ object.hours.string }}h
      </template>
      <template v-else-if="object.hours.number > 0">
        {{ object.hours.string }}:{{ object.minutes.string }}
      </template>
      <template v-else>
        {{ object.minutes.string }}:{{ object.seconds.string }}
      </template>
    </span>
  </span>
</template>
<script>
export default {
  name: "GameEndCountdown",
  props: {
    endTime: {
      type: Number,
      required: true
    },
    endTimeLabel: {
      type: String,
      default: "Game Over"
    },
    endInTimeLabel: {
      type: String,
      default: "Ends In"
    }
  },
  data() {
    return {
      interval: null,
      globalTime: 0
    }
  },
  methods: {
    onTick() {
      this.globalTime = Date.now()
    }
  },
  created() {
    this.interval = setInterval(this.onTick, 1000)
    this.onTick()
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  computed: {
    difference() {
      return this.endTime - this.globalTime
    },
    time() {
      return this.difference > 0 ? this.difference : 0
    },
    endClose() {
      return this.endTime - this.globalTime < 1000 * 60 * 2
    },
    ended() {
      // console.log("END TIME", this.endTime)
      // console.log("GLOBAL", this.globalTime)
      // console.log("COMPAIRE", this.endTime < this.globalTime)
      return this.endTime < this.globalTime
    },
    lastHour() {
      return (this.endTime - this.globalTime) / 1000 / 60 < 60
    },
    object() {
      let hours = this.time / (1000 * 60 * 60)
      let absoluteHours = Math.floor(hours)
      let h = absoluteHours > 9 ? absoluteHours : "0" + absoluteHours
      // Get remainder from hours and convert to minutes
      let minutes = (hours - absoluteHours) * 60
      let absoluteMinutes = Math.floor(minutes)
      let m = absoluteMinutes > 9 ? absoluteMinutes : "0" + absoluteMinutes
      // Get remainder from minutes and convert to seconds
      let seconds = (minutes - absoluteMinutes) * 60
      let absoluteSeconds = Math.floor(seconds)
      let s = absoluteSeconds > 9 ? absoluteSeconds : "0" + absoluteSeconds

      return {
        seconds: {
          absoluteSeconds: s,
          string: s
        },
        minutes: {
          number: absoluteMinutes,
          string: m
        },
        hours: {
          number: absoluteHours,
          string: h
        }
      }
    }
  }
}
</script>

<style lang="scss">
.game-end-countdown {
  display: inline-block;
  white-space: nowrap;
  background: $color-white;
  margin: {
    right: 12px;
    left: 5px;
  }
  border-radius: 4px;
  overflow: hidden;
  height: 16px;
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;
  color: $color-black;

  &.last-hour:not(.ended) {
    .text {
      display: none;
    }
  }

  &.ended {
    color: $color-white;
    background: $color-wrong;
  }
  span {
    display: inline-block;
    vertical-align: top;
  }
  .text {
    text-transform: uppercase;
    padding: 0 4px 0 5px !important;
  }
  .time {
    background: $color-correct;
    color: $color-white;
    padding: 0 4px !important;
  }
  &.end-close .time {
    background: $color-wrong;
  }
}
</style>
