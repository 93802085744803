var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "game-end-countdown",
      class: {
        "end-close": _vm.endClose,
        ended: _vm.ended,
        "last-hour": _vm.lastHour,
      },
    },
    [
      _vm.ended
        ? _c("span", { staticClass: "text" }, [
            _vm._v(" " + _vm._s(_vm.endTimeLabel) + " "),
          ])
        : _c("span", { staticClass: "text" }, [
            _vm._v(" " + _vm._s(_vm.endInTimeLabel) + " "),
          ]),
      !_vm.ended
        ? _c(
            "span",
            { staticClass: "time" },
            [
              _vm.object.hours.number > 9
                ? [_vm._v(" " + _vm._s(_vm.object.hours.string) + "h ")]
                : _vm.object.hours.number > 0
                ? [
                    _vm._v(
                      " " +
                        _vm._s(_vm.object.hours.string) +
                        ":" +
                        _vm._s(_vm.object.minutes.string) +
                        " "
                    ),
                  ]
                : [
                    _vm._v(
                      " " +
                        _vm._s(_vm.object.minutes.string) +
                        ":" +
                        _vm._s(_vm.object.seconds.string) +
                        " "
                    ),
                  ],
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }