var render = function () {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rtb-header" }, [
    _c("div", { staticClass: "rtb-header__inner" }, [
      _c(
        "div",
        { staticClass: "rtb-header__left" },
        [
          _c(
            "div",
            {
              class:
                ((_obj = {
                  "rtb-header__logo-wrapper": true,
                }),
                (_obj["rtb-header__logo-wrapper--size--" + _vm.logoSize] =
                  _vm.logoSize !== undefined),
                _obj),
            },
            [
              !!_vm.customLogo
                ? [
                    _c("div", {
                      class:
                        ((_obj$1 = {
                          "rtb-header__logo": true,
                        }),
                        (_obj$1["rtb-header__logo--size--" + _vm.logoSize] =
                          _vm.logoSize !== undefined),
                        _obj$1),
                      style: "background-image: url(" + _vm.customLogo + ")",
                    }),
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "rtb-header__logo" },
                      [
                        _c(
                          "IconBase",
                          {
                            staticClass: "rtb-header__gg-logo",
                            attrs: { size: "35" },
                          },
                          [_c("IconGoGame")],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
              _c(
                "div",
                { staticClass: "rtb-header__game-name-wrapper" },
                [_vm._t("game-name")],
                2
              ),
            ],
            2
          ),
          _vm.hasPreGame && _vm.endTime && _vm.shouldShowHostlessGameCountDown
            ? _c("GameEndCountdown", {
                staticClass: "flex-shrink-0",
                attrs: { endTime: _vm.endTime },
              })
            : _vm.isHost && !_vm.isModerator && _vm.endTime
            ? _c("GameEndCountdown", {
                staticClass: "flex-shrink-0",
                attrs: { endTime: _vm.endTime },
              })
            : _vm._e(),
          _vm._t("left"),
        ],
        2
      ),
      _vm.$slots.center
        ? _c(
            "div",
            { staticClass: "rtb-header__center" },
            [_vm._t("center")],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "rtb-header__right" }, [_vm._t("right")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }