<template>
  <div class="rtb-header">
    <div class="rtb-header__inner">
      <div class="rtb-header__left">
        <div
          :class="{
            'rtb-header__logo-wrapper': true,
            [`rtb-header__logo-wrapper--size--${logoSize}`]:
              logoSize !== undefined
          }"
        >
          <template v-if="!!customLogo">
            <div
              :class="{
                'rtb-header__logo': true,
                [`rtb-header__logo--size--${logoSize}`]: logoSize !== undefined
              }"
              :style="`background-image: url(${customLogo})`"
            />
          </template>
          <template v-else>
            <div class="rtb-header__logo">
              <IconBase class="rtb-header__gg-logo" size="35">
                <IconGoGame />
              </IconBase>
            </div>
          </template>
          <div class="rtb-header__game-name-wrapper">
            <slot name="game-name" />
          </div>
        </div>

        <GameEndCountdown
          v-if="hasPreGame && endTime && shouldShowHostlessGameCountDown"
          :endTime="endTime"
          class="flex-shrink-0"
        />
        <GameEndCountdown
          v-else-if="isHost && !isModerator && endTime"
          :endTime="endTime"
          class="flex-shrink-0"
        />
        <slot name="left"></slot>
      </div>
      <div class="rtb-header__center" v-if="$slots.center">
        <slot name="center"></slot>
      </div>
      <div class="rtb-header__right">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import GameEndCountdown from "@/components/GameEndCountdown.vue"

import IconBase from "@/components/Icon/IconBase"
import IconGoGame from "@/components/Icon/IconGoGame"

const LOGO_SIZES = ["sm"]

export default {
  name: "Header",
  components: {
    GameEndCountdown,
    IconBase,
    IconGoGame
  },
  props: {
    logoSize: {
      type: String,
      default: undefined,
      validator: val => LOGO_SIZES.includes(val)
    }
  },
  computed: {
    ...mapGetters(["game"]),
    ...mapGetters("auth", ["client", "isHost", "isModerator", "hasPreGame"]),
    endTime() {
      return this.client?.endTimestamp
    },
    customLogo() {
      return this.$theme.getImage("expoLogo")
    },
    shouldShowHostlessGameCountDown() {
      return (
        (this.game?.hostless || this.game?.hostless2) && this.game?.duration
      )
    }
  }
}
</script>

<style lang="scss">
.rtb-header {
  padding: {
    top: 0;
    bottom: 0;
    left: 32px;
    right: 32px;
  }

  position: relative;
  color: #fff;

  &__inner {
    position: relative;
    display: flex;
  }

  &__left,
  &__right {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &__right {
    justify-content: flex-end;
  }

  &__center {
    align-items: center;
    display: flex;
  }

  &__logo-wrapper {
    display: grid;
    grid-template-columns: 54px auto;
    align-items: center;
    grid-gap: 18px;
    margin-right: 16px;

    &--size {
      &--sm {
        grid-template-columns: 48px auto;
      }
    }
  }

  &__logo {
    width: 100%;
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #fff;
    color: #000;
    border-radius: 12px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &--size {
      &--sm {
        width: 48px;
        height: 48px;
      }
    }
  }

  &__title {
    margin: 0;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.286;
    letter-spacing: 0.01em;
  }

  &__subtitle {
    font-size: 11px;
    line-height: 1.27;
    letter-spacing: 0.01em;
    margin: 0;
    color: #bebebe;
  }

  &__tip {
    display: inline-flex;
    align-items: center;
    background: rgba(41, 41, 50, 0.7);
    padding: 8px 16px;
    border-radius: 8px;
    white-space: nowrap;
    line-height: 22px;
  }
}
</style>
