var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g", [
    _c("path", {
      attrs: {
        d: "M10 9.74l-3.03-.99.32-1.06-3.03.9 1.89 2.54.4-1.06 1.48.49a3.2 3.2 0 11-1.88-4.6c.46.16.88.41 1.23.75h.08a.16.16 0 00.16-.09c.2-.43.44-.85.74-1.22V5.3a.16.16 0 00-.08-.16 5.08 5.08 0 101.8 4.75.16.16 0 00-.08-.16z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M14.58 4.25a5 5 0 00-6.3 3.2c-.1.23-.19.48-.25.73.02.04.05.07.08.08.02.04.05.06.09.08l3.03.99-.41 1.06 3.03-.9-1.8-2.54-.41 1.07-1.48-.5a3.2 3.2 0 11.59 3.79.16.16 0 00-.1-.02.16.16 0 00-.16.17c-.2.44-.44.85-.74 1.23v.08a.16.16 0 00.08.16c.47.37 1 .64 1.56.82a5.01 5.01 0 003.2-9.5z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }